




























import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

export interface Country {
  id: number;
  name: string;
  tagline: string;
  logo: string;
  status: string;
}

@Component
export default class CountryItem extends Vue {
  public userRole: any = 'staff'
  public userUrl:any = 'staff'
  @Prop() country!: Country
  @Prop() index!: number
  mounted () {
    let userData = this.$store.state.userData
    this.userRole = userData.role
    if (this.userRole === 'super-admin') {
      this.userUrl = 'sadmin'
    } else {
      this.userUrl = 'staff'
    }
  }
  setStatus (country: any, index: number) {
    this.$emit('changeStatus', country, index)
  }
}
