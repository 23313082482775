



























import CountryItem from '@/components/SAdmin/CountryItem.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Component({
  components: {
    CountryItem
  }
})
export default class Countries extends Vue {
  public query = ''
  public limit = 500
  public offset = 0
  public brandId = 0
  public actionCountry: any = null
  public countries: any[] = []
  public totalCount = 0
  public busy: boolean = false
  public product: {name: string, section: string, description:string, images: any[]} = {
    name: '',
    section: '',
    description: '',
    images: []
  }
  public productDetails: object = {}
  public serviceEnabled: any = ''

  getCountries (clear = true) {
    this.busy = true
    this.$store.dispatch('getCountriesList', { query: this.query, limit: this.limit, offset: this.offset, service_enabled: this.serviceEnabled }).then((response) => {
      if (clear) this.countries = []
      this.countries.push(...response.response.body.data)
      this.totalCount = response.response.body.count
      this.busy = false
    }, (response) => {
      this.busy = false
    })
  }

  changeStatus (country: any, index: number) {
    this.$store.dispatch('toggleCountryStatus', country).then((response) => {
      this.$notify({ type: 'success', text: response.response.body.message })
      this.getCountries()
    }, (response) => {
      this.busy = false
    })
  }

  loadMore () {
    this.$data.offset += this.$data.limit
    this.getCountries(false)
  }

  @Watch('query')
  onSearch () {
    Vue.common.delay(() => {
      this.offset = 0
      this.getCountries()
    }, 500)()
  }

  created () {
    this.getCountries()
    this.$store.dispatch('getAppSettingsKeyValuePair')
  }

  refresh () {
    this.offset = 0
    this.getCountries()
  }

  editProductDetails (product:object) {
    this.productDetails = product
  }

  get hasLoadMore (): boolean {
    return this.countries.length < this.totalCount
  }
}
