var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "green-border-box outer-box" }, [
    _c("div", { staticClass: "inner-box" }, [
      _c("span", { staticClass: "title", attrs: { title: _vm.country.name } }, [
        _vm._v(_vm._s(_vm.country.name))
      ]),
      _c("br"),
      _c(
        "span",
        { staticClass: "description", attrs: { title: _vm.country.iso_code } },
        [
          _vm._v(
            _vm._s(_vm.country.iso_code) + " | " + _vm._s(_vm.country.isd_code)
          )
        ]
      ),
      _c("br"),
      _c("div", { staticClass: "text-center" }, [
        _vm.country.service_enabled == 0
          ? _c(
              "button",
              {
                staticClass: "btn-positive",
                attrs: { "data-toggle": "modal", type: "button" },
                on: {
                  click: function($event) {
                    return _vm.setStatus(_vm.country, _vm.index)
                  }
                }
              },
              [_vm._v("ENABLE")]
            )
          : _c(
              "button",
              {
                staticClass: "btn-negative",
                attrs: {
                  "data-target": "#deactivate-country-popup",
                  "data-toggle": "modal",
                  type: "button"
                },
                on: {
                  click: function($event) {
                    return _vm.setStatus(_vm.country, _vm.index)
                  }
                }
              },
              [_vm._v("DISABLE")]
            )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }